import React, { Fragment } from "react";

import WhiteSpace from '../design/WhiteSpace'
import WhiteBox from '../design/WhiteBox'
import Collapsible from '../design/Collapsible'
import WhiteBreakBottom from '../design/WhiteBreakBottom'
import WhiteBreakTop from '../design/WhiteBreakTop'
import JobHeader from '../elements/JobHeader'
import JobInfo from '../elements/JobInfo'
import JobInfoInline from '../elements/JobInfoInline'
import JobAddress from '../elements/JobAddress'
import CallPhone from '../elements/CallPhone'
import CargoList from '../elements/CargoList'
import * as JobStub from '../../utils/JobStub'
import * as CommonUtils from '../../utils/CommonUtils'
import * as StatusFetching from '../../utils/StatusFetching'

export default class Stage7 extends React.Component {

    acceptStage = () => {
        if (JobStub.isStubJob()) {
            JobStub.callStubSource('/stage7/accept')
            .then(() => {
                StatusFetching.fetchStubStatus(this.props.home)
            })
            return
        }

        CommonUtils.callSource('api/stage/stage7/accept', {
            job_guid: this.props.jobGUID,
            job_no: this.props.jobNo,
            point: this.props.statusData['Пункт погрузки'],
            order: this.props.statusData['Номер по сдаче'],
        }, this.props.home)
        .then(() => {
            StatusFetching.fetchStatus(this.props.home)
        })
    }

    render() {
        let statusData = this.props.statusData
        let imqStatus = this.props.imqStatus
        let supportMail = this.props.supportMail
        let oDContart = this.props.oDContart
        return (
            <div>  
                {this.props.hover}
                <div className="d-flex flex-column lgtrn-vh-100">
                    <div className="flex-grow-1 d-flex flex-column">
                        
                        <JobHeader title={statusData['Номер Рейса']} supportMail={supportMail} />
                        <WhiteSpace />
                        <WhiteBox>
                            <JobInfo 
                                label1="Номер по сдаче"
                                label1Width="8"
                                label2="Тип"
                                value1={statusData['Номер по сдаче']} 
                                value2={statusData['Тип']} 
                                noWhiteBox="true"
                            />
                            <JobInfo 
                                label1="План прибытия"
                                label1Width="8"
                                label2="План разгрузки"
                                label2Width="8"
                                value1={statusData['Время план прибытия']} 
                                value2={statusData['Время план разгрузки']} 
                                fontBold="true"
                                noWhiteBox="true"
                            />
                            <JobInfo 
                                label1="Доставить с"
                                label1Width="8"
                                label2="Доставить до"
                                label2Width="8"
                                value1={statusData['Доставить с']} 
                                value2={statusData['Доставить по']} 
                                spaceAfter="true"
                                noWhiteBox="true"
                            />
                            <JobAddress title="Адрес" address={statusData['Адрес']} noWhiteBox="true" />
                            {statusData['Тип'] === 'Клиент' &&
                                <JobInfoInline
                                    label="Контакт"
                                    value={statusData['Контакт']} 
                                    noWhiteBox="true"
                                />
                            }
                            <JobInfoInline
                                label="Телефон"
                                value={statusData['Телефон']} 
                                noWhiteBox="true"
                            />
                            {statusData['Тип'] === 'Клиент' &&
                                <JobInfoInline
                                    label="Точка выгрузки"
                                    value={statusData['Точка Выгрузки']} 
                                    spaceAfter="true"
                                    noWhiteBox="true"
                                />
                            }
                            {statusData['Тип'] === 'ТК' &&
                                <h3 className="lgtrn-company-header text-center mb-4 mt-3">{statusData['Точка Выгрузки']}</h3>
                            }
                            <div className="row mb-3"><div className="col">
                                <button className="btn lgtrn-btn-success btn-block text-uppercase lgtrn-btn" onClick={this.acceptStage} disabled={imqStatus !== null}>Закрыть точку выгрузки</button>
                            </div></div>
                        </WhiteBox>
                        <WhiteBreakBottom />

                        {
                            Object.keys(statusData.clients).map((clientCode, index) => {
                                let client = statusData.clients[clientCode]
                                let title = client['Получатель']
                                if (client['Тип Строки'] === 'Возврат') {
                                    title = 'Получение возврата ' + title
                                } else {
                                    title = 'Получатель ' + title
                                }
                                return <Fragment key={index}>
                                    <WhiteBreakTop />
                                    <Collapsible 
                                        title={title} 
                                        subtitle={client['Тип Строки'] === 'Клиент' ? '' : client['Точка Выгрузки Название']}
                                        key={index}
                                        collapsed={true}
                                    >
                                        {client['Тип Строки'] === 'ТК' &&
                                            <JobInfoInline
                                                label="Адрес"
                                                value={client['Получатель Город']} 
                                                noWhiteBox="true"
                                            />
                                        }
                                        {client['Тип Строки'] === 'ТК' &&
                                            <JobInfoInline
                                                label="Контакт"
                                                value={client['Получатель Контакт']} 
                                                noWhiteBox="true"
                                            />
                                        
                                        }
                                        {client['Тип Строки'] === 'ТК' &&
                                            <JobInfoInline
                                                label="Скорость отправки"
                                                value={client['Скорость']} 
                                                noWhiteBox="true"
                                            />
                                        }
                                        {client['Тип Строки'] === 'ТК' &&
                                            <JobInfoInline
                                                label="Отправитель"
                                                value={client['Отправитель']} 
                                                noWhiteBox="true"
                                            />
                                        }
                                        {client['Тип Строки'] === 'ТК' &&
                                            <JobInfoInline
                                                label="Плательщик"
                                                value={client['Плательщик']} 
                                                spaceAfter="true"
                                                noWhiteBox="true"
                                            />
                                        }
                                        <CargoList data={client['ext']} colored={true} />
                                        <div className="row mt-4 mb-3"><div className="col">
                                            {(client['Статус разгрузки'] === 'Delivered' || client['Статус разгрузки'] === 'NotReceived') &&
                                                <div className="lgtrn-button-like lgtrn-button-like-success text-uppercase">
                                                    <img src="./img/check-green.svg" alt="check" className="img-fluid" />
                                                    <span className="pl-3">{client['Text1']}</span>
                                                </div>
                                            }
                                            {(
                                                client['Статус разгрузки'] === 'DeliveredPartly' || client['Статус разгрузки'] === 'DeliveredReturn' || client['Статус разгрузки'] === 'DeliveredAct' ||
                                                client['Статус разгрузки'] === 'ReceivedPartly' || client['Статус разгрузки'] === 'Received' || client['Статус разгрузки'] === 'ReceivedAct'
                                            ) &&
                                                <div className="lgtrn-button-like lgtrn-button-like-warning text-uppercase">
                                                    <img src="./img/check-orange.svg" alt="check" className="img-fluid" />
                                                    <span className="pl-3">{client['Text1']}</span>
                                                </div>
                                            }
                                        </div></div>
                                    </Collapsible>
                                    <WhiteBreakBottom />
                                </Fragment>
                            })
                        }

                    </div>
                    <WhiteBreakTop />
                    <CallPhone oDContart={oDContart} />
                </div>
            </div>
        )
    }
}
