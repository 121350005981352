import React from "react";

import WhiteBox from '../design/WhiteBox'

export default class JobInfoInline extends React.Component {
    
    renderContent = () => {
        let labelWidth = 3
        if (this.props.labelWidth) {
            labelWidth = this.props.labelWidth
        }
        let spaceAfter = ' mb-2'
        if (this.props.spaceAfter) {
            spaceAfter = ' mb-4'
        }
        let fontBold = ''
        if (this.props.fontBold) {
            fontBold = ' font-weight-bold'
        }

        return (
            <div className={'row'+spaceAfter}>
                <div className={'lgtrn-info-label-2 col-' + labelWidth + fontBold}>{this.props.label}</div>
                <div className={'lgtrn-info-text-2 col-' + (12-labelWidth) + fontBold}>{this.props.value}</div>
            </div>
        )
    }

    renderWhiteBox = () => {
        return (
            <WhiteBox>
                {this.renderContent()}
            </WhiteBox>
        )
    }

    render() {
        return (
            <>
                {(this.props.noWhiteBox) ? this.renderContent() : this.renderWhiteBox()}
            </>
        )
    }
}
