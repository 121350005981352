import React from "react";

import WhiteBox from '../design/WhiteBox'
import * as JobStub from '../../utils/JobStub'

export default class CallPhone extends React.Component {
    
    getTip = () => {
        return JobStub.isStubJob() ? 'Недоступно для демоверсии' : null
    } 

    onCallClick = (e) => {
        if (JobStub.isStubJob()) {
            e.preventDefault()
        }
    }

    clearPhone = (phone) => {
        let ret = ''
        let allowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '+', ',']
        for (let char of phone) {
            if (allowed.includes(char)) {
                ret += char
            }
        }
        if (ret.substring(0, 1) === '8') {
            ret = '+7' + ret.substring(1)
        }
        return ret
    }

    render() {
        let phone = this.clearPhone(this.props.oDContart)
        return (
            <>
                <WhiteBox>
                    <div className={'row mb-' + (this.props.space ? '10' : '4')}><div className="col">
                        <div className="d-flex flex-row">
                            <div className="lgtrn-call-info flex-grow-1">
                                Связь с диспетчером при возникновении вопросов, сложностей при выполнении рейса
                            </div>
                            <div className="lgtrn-call-icon">
                                <a href={"tel:" + phone} data-tip={this.getTip()} onClick={this.onCallClick}>
                                    <img src="./img/phone.svg" alt="phone" className="img-fluid" />
                                </a>
                            </div>
                        </div>
                    </div></div>
                </WhiteBox>
            </>
        )
    }
}

