import React from "react";

import WhiteSpace from '../design/WhiteSpace'
import WhiteFill from '../design/WhiteFill'
import WhiteBox from '../design/WhiteBox'
import JobHeader from '../elements/JobHeader'
import JobInfo from '../elements/JobInfo'
import JobInfoInline from '../elements/JobInfoInline'
import JobAddress from '../elements/JobAddress'
import CallPhone from '../elements/CallPhone'
import * as JobStub from '../../utils/JobStub'
import * as CommonUtils from '../../utils/CommonUtils'
import * as StatusFetching from '../../utils/StatusFetching'
import * as ServerMessages from '../../utils/ServerMessages'

export default class Stage5 extends React.Component {

    acceptStage = () => {
        if (JobStub.isStubJob()) {
            JobStub.callStubSource('/stage5/accept')
            .then(() => {
                StatusFetching.fetchStubStatus(this.props.home)
            })
            return
        }

        if (this.props.planningFinished === 0) {
            let text = <span>Внимание!<br />В рейсе необходимо установить отметку &laquo;Планирование завершено&raquo;.<br />Свяжитесь с диспетчером.</span>
            ServerMessages.messageDrawerToggle(this.props.home, text)
            return
        }
        CommonUtils.callSource('api/stage/stage5/accept', {
            job_guid: this.props.jobGUID,
            job_no: this.props.jobNo,
            point: this.props.statusData['Пункт погрузки'],
            order: this.props.statusData['Номер по сдаче'],
        }, this.props.home)
        .then(() => {
            StatusFetching.fetchStatus(this.props.home)
        })
    }

    render() {
        let statusData = this.props.statusData
        let imqStatus = this.props.imqStatus
        let supportMail = this.props.supportMail
        let oDContart = this.props.oDContart
        return <div>  
            {this.props.hover}
            <div className="d-flex flex-column lgtrn-vh-100">
                <div className="flex-grow-1 d-flex flex-column">
                    
                    <JobHeader title={statusData['Номер Рейса']} supportMail={supportMail} />
                    <WhiteSpace />
                    <JobInfo 
                        label1="Номер по сдаче"
                        label1Width="8"
                        label2="Тип"
                        value1={statusData['Номер по сдаче']} 
                        value2={statusData['Тип']} 
                    />
                    <JobInfo 
                        label1="План прибытия"
                        label1Width="8"
                        label2="План разгрузки"
                        label2Width="8"
                        value1={statusData['Время план прибытия']} 
                        value2={statusData['Время план разгрузки']} 
                        fontBold="true"
                    />
                    <JobInfo 
                        label1="Доставить с"
                        label1Width="8"
                        label2="Доставить до"
                        label2Width="8"
                        value1={statusData['Доставить с']} 
                        value2={statusData['Доставить по']} 
                        spaceAfter="true"
                    />
                    <JobAddress title="Адрес" address={statusData['Адрес']} />
                    {statusData['Тип'] === 'Клиент' &&
                        <JobInfoInline
                            label="Контакт"
                            value={statusData['Контакт']} 
                            labelWidth="4"
                        />
                    }
                    <JobInfoInline
                        label="Телефон"
                        value={statusData['Телефон']} 
                        labelWidth="4"
                    />
                    {statusData['Тип'] === 'Клиент' &&
                        <JobInfoInline
                            label="Точка выгрузки"
                            value={statusData['Точка Выгрузки']} 
                            spaceAfter="true"
                            labelWidth="4"
                        />
                    }
                    {statusData['Тип'] === 'ТК' &&
                        <WhiteBox>
                            <h3 className="lgtrn-company-header text-center mb-4 mt-3">{statusData['Точка Выгрузки']}</h3>
                        </WhiteBox>
                    }
                    
                    <WhiteFill />

                </div>
                <WhiteBox>
                    <div className="row mb-3"><div className="col">
                        <button className="btn lgtrn-btn-success btn-block text-uppercase lgtrn-btn" onClick={this.acceptStage} disabled={imqStatus !== null}>Прибыл на точку</button>
                    </div></div>
                </WhiteBox>
                <CallPhone oDContart={oDContart} />
            </div>
        </div>
    }
}
